import { checkRole, MySpinner } from "./Utils";
import { FaArrowCircleLeft, FaEdit } from "react-icons/fa";
import { Textarea } from "@chakra-ui/react";
import React from "react";

export const Aktuelles = ({
  handleInputChange,
  editingMode,
  spinner,
  text,
  handleClick,
  setEditingMode,
  value,
  user,
}) => {
  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg overflow-hidden shadow-lg md:shadow-sm mb-6 p-3 relative mt-8 md:mt-0">
      {spinner && <MySpinner />}
      <div className="flex justify-between items-center mb-5">
        <div className="flex">
          <div className="font-bold">Aktuelles</div>
        </div>
        {!editingMode && checkRole(user, "admin") && (
          <button onClick={() => setEditingMode(!editingMode)}>
            <FaEdit
              size={25}
              className={`text-blue-700 ${!text && "invisible"}`}
            />
          </button>
        )}
        {editingMode && (
          <button onClick={() => setEditingMode(!editingMode)}>
            <FaArrowCircleLeft size={25} className="text-red-600" />
          </button>
        )}
      </div>
      {!editingMode && (
        <div className="whitespace-pre-wrap md:overflow-auto lg:overflow-auto lg:h-96">
          {text ? text : <MySpinner />}
        </div>
      )}
      {editingMode && (
        <>
          <Textarea
            rows={20}
            value={value}
            onChange={handleInputChange}
            placeholder="Nur zu!"
          />

          <button className="submitButton" onClick={handleClick}>
            Speichern
          </button>
        </>
      )}
    </div>
  );
};
