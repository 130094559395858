import { graphql, Link, useStaticQuery } from "gatsby";
import React, { useEffect, useState } from "react";
import { BuyMeACoffee, checkRole, userName } from "./Utils";
import { martin } from "./Auth";
import { useAuth0 } from "@auth0/auth0-react";
import "regenerator-runtime/runtime";
import { Chatbox } from "./Chatbox";
import { Boss } from "./Boss";
import { Aktuelles } from "./Aktuelles";
import { Preview } from "./Preview";

const HomeSidebar = () => {
  const { allContentfulPost } = useStaticQuery(graphql`
    query {
      allContentfulPost(sort: { fields: publishedAt, order: ASC }) {
        nodes {
          title
          draft
          summary
          publishedAt(formatString: "DD-MM-YYYY")
          serie {
            title
          }
          gallery {
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: BLURRED
              quality: 100
              width: 1000
              aspectRatio: 1.77
            )
          }
        }
      }
    }
  `);

  var today = new Date();
  var date =
    (today.getDate() < 10 ? "0" + today.getDate() : today.getDate()) +
    "." +
    (today.getMonth() < 9
      ? "0" + (today.getMonth() + 1)
      : today.getMonth() + 1) +
    "." +
    today.getFullYear();
  var time =
    (today.getHours() < 10 ? "0" + today.getHours() : today.getHours()) +
    ":" +
    (today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes());
  var dateTime = date + " - " + time;

  const { isAuthenticated, user, loginWithRedirect } = useAuth0();
  const drafts = allContentfulPost.nodes;
  const [editingMode, setEditingMode] = useState(false);
  const [editNumber, setEditNumber] = useState(null);
  const [text, setText] = useState("");
  const [spinner, setSpinner] = useState(false);
  let [valueMessage, setValueMessage] = useState(null);

  const [messages, setMessages] = useState(null);

  const [editMode, setEditMode] = useState(false);
  const [normalMode, setNormalMode] = useState(true);

  let handleInputChange = (e) => {
    setValue(e.target.value);
  };

  const contentful = require("contentful-management");
  async function Connect() {
    let client = contentful.createClient({
      accessToken: "CFPAT-jLjpO9lQUXtJ4wgWWXTuwdayKLQMXWZ0s7aUsX9km3U",
    });
    let space = await client.getSpace("j3cnxbdaejpl");
    return await space.getEnvironment("master");
  }
  async function ShowMessage(env, postID) {
    let post = await env.getEntry(postID);
    setText(post.fields.message?.["de"]);
    setValue(post.fields.message?.["de"]);
    setMessages(post.fields.chat["de"]);
  }
  useEffect(() => {
    (async () => {
      let env = await Connect();
      await ShowMessage(env, "1R1m1cJtqjEFvlmcwPrwEt");
    })();
  }, []);
  let [value, setValue] = useState(null);

  const handleSaveMessage = () => {
    async function SaveMessage(env, postID) {
      let post = await env.getEntry(postID);
      if (post.fields.chat["de"].length > 50) {
        post.fields.chat["de"].splice(0, 1);
        post.fields.chat["de"].push({
          userId: user.sub,
          name: userName(user),
          message: valueMessage,
          date: dateTime,
        });
      } else {
        post.fields.chat["de"].push({
          userId: user.sub,
          name: userName(user),
          message: valueMessage,
          date: dateTime,
        });
      }
      await setMessages(post.fields.chat["de"]);
      try {
        await post.update();
        post = await env.getEntry(postID);
        await post.publish();
        document.getElementById("output").value = "";
      } catch (err) {
        alert("Ups, da ist etwas schief gelaufen!");
        let post = await env.getEntry(postID);
        setMessages(post.fields.chat["de"]);
      }
    }
    (async () => {
      let env = await Connect();
      await SaveMessage(env, "1R1m1cJtqjEFvlmcwPrwEt");
    })();
  };

  const handleClick = () => {
    async function UpdateMessage(env, postID) {
      setSpinner(true);
      setEditingMode(false);
      let post = await env.getEntry(postID);
      post.fields.message = { de: value };
      await setText(post.fields.message["de"]);
      setSpinner(false);
      await post.update();
      post = await env.getEntry(postID);
      await post.publish();
    }
    (async () => {
      let env = await Connect();
      await UpdateMessage(env, "1R1m1cJtqjEFvlmcwPrwEt");
    })();
  };

  const handleEditButton = (index, message) => {
    setEditMode(!editMode);
    setNormalMode(!normalMode);
    setEditNumber(index);
    document.getElementById("output").value = message;
    document.getElementById("output").scrollIntoView({
      behavior: "smooth",
    });
  };

  const handleEdit = () => {
    async function EditMessage(env, postID) {
      document.getElementById("output").value = "";
      setEditMode(false);
      setNormalMode(true);
      let post = await env.getEntry(postID);
      post.fields.chat["de"][editNumber]["message"] = valueMessage;
      await setMessages(post.fields.chat["de"]);
      try {
        await post.update();
        post = await env.getEntry(postID);
        await post.publish();
      } catch (err) {
        alert("Upsi.. Möglicherweise ist ein Fehler aufgetreten.");
        let post = await env.getEntry(postID);
        setMessages(post.fields.chat["de"]);
      }
    }
    (async () => {
      let env = await Connect();
      await EditMessage(env, "1R1m1cJtqjEFvlmcwPrwEt");
    })();
  };

  const handleDelete = (index) => {
    async function DeleteMessage(env, postID) {
      document.getElementById("output").value = "";
      setEditMode(false);
      setNormalMode(true);
      let post = await env.getEntry(postID);
      post.fields.chat["de"].splice(index, 1);
      await setMessages(post.fields.chat["de"]);
      try {
        await post.update();
        post = await env.getEntry(postID);
        await post.publish();
      } catch (err) {
        alert("Upsi.. Möglicherweise ist ein Fehler aufgetreten.");
        let post = await env.getEntry(postID);
        setMessages(post.fields.chat["de"]);
      }
    }
    (async () => {
      let env = await Connect();
      await DeleteMessage(env, "1R1m1cJtqjEFvlmcwPrwEt");
    })();
  };

  let handleInputChangeMessage = (e) => {
    let inputValueMessage = e.target.value;
    setValueMessage(inputValueMessage);
  };

  const PleaseLogin = () => {
    return (
      !isAuthenticated && (
        <div className="order-1">
          <div className="">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-3 mb-4 md:mt-0 ">
              <div className="text-xl mb-5 dark:text-white">Willkommen!</div>

              <p className="dark:text-white">
                Das ist ein privater Blog für mich, meine Familie und Freunde.
                Du hast noch keinen Account? Dann{" "}
                <button className="link" onClick={loginWithRedirect}>
                  registriere
                </button>{" "}
                dich und lass dich von mir persönlich freischalten.
              </p>
            </div>
          </div>
        </div>
      )
    );
  };
  const WaitingForAdmin = () => {
    return (
      isAuthenticated &&
      !checkRole(user) && (
        <div className="order-1">
          <div className="">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-3 mb-4 md:mt-0 ">
              <div className="text-xl mb-5 dark:text-white">Willkommen!</div>
              {!checkRole(user) && (
                <>
                  <p>
                    Dein Account wurde vom Admin noch nicht bestätigt. Dies kann
                    bis zu 48 Stunden dauern.
                  </p>
                  <p className="mt-2">Deine Email: {user.email}</p>
                </>
              )}
            </div>
          </div>

          <div className="hidden md:contents">
            <BuyMeACoffee />
          </div>
        </div>
      )
    );
  };

  const WelcomeText = () => {
    return (
      user?.sub !== martin && (
        <p>
          Schön, dass du vorbeischaust
          {`${userName(user) ? `, ${userName(user)}` : ""}`}! Hier findest du
          Blogbeiträge zu allen möglichen Themen. Falls du nach etwas Bestimmten
          suchst, dann kannst du den{" "}
          <Link to="/beitraege" className="link">
            Suchfilter
          </Link>{" "}
          nutzen.
        </p>
      )
    );
  };

  const GuestArticle = () => {
    return (
      <div className="hidden">
        <div className="bg-white rounded-lg shadow-sm p-3 mb-4">
          <div className="text-xl mb-5">Gastbeiträge</div>
          <div>
            Du hast Lust an der Seite mitzuwirken? Gastbeiträge sind gerne
            gesehen.{" "}
            <Link to="/kontakt">
              <span className="text-blue-500">Kontaktiere mich!</span>
            </Link>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {checkRole(user) && (
        <>
          <div className="hidden md:contents">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-3 mb-4 mt-8 lg:mt-0">
              <Boss user={user} />
              <WelcomeText />
            </div>
          </div>
          <Aktuelles
            editingMode={editingMode}
            text={text}
            spinner={spinner}
            handleInputChange={handleInputChange}
            setEditingMode={setEditingMode}
            value={value}
            handleClick={handleClick}
            user={user}
          />
          <Chatbox
            handleSaveMessage={handleSaveMessage}
            messages={messages}
            user={user}
            handleDelete={handleDelete}
            editMode={editMode}
            handleEdit={handleEdit}
            handleEditButton={handleEditButton}
            handleInputChangeMessage={handleInputChangeMessage}
            normalMode={normalMode}
          />
          <Preview drafts={drafts} />
          <GuestArticle />
          <div className="hidden md:contents lg:hidden">
            <BuyMeACoffee />
          </div>
        </>
      )}
      <WaitingForAdmin />
      <PleaseLogin />
    </div>
  );
};

export default HomeSidebar;
