import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { Textarea } from "@chakra-ui/react";
import React from "react";

export const Chatbox = ({
  editMode,
  handleDelete,
  handleEdit,
  handleEditButton,
  user,
  handleSaveMessage,
  messages,
  normalMode,
  handleInputChangeMessage,
}) => {
  return (
    <div className="bg-white rounded-lg p-3 mb-4 dark:bg-gray-800">
      <div className="text-center text-lg mt-3">Chatbox</div>
      <div className="mt-6 h-64 flex flex-col-reverse overflow-auto pr-1">
        <div>
          {messages?.map((item, index) => (
            <>
              <div
                className="border-b pb-6 mb-8 last:border-0 last:mb-0"
                key={index}
              >
                <div className="flex justify-between h-[24px] items-center">
                  <div className="text-xs opacity-50">{item.date}</div>
                  <div className="flex">
                    {user.sub === item.userId && (
                      <>
                        <button
                          onClick={() => handleEditButton(index, item.message)}
                          className=""
                        >
                          <FaEdit size={12} className="opacity-30" />
                        </button>
                        <div className="flex">
                          <div className="ml-3">
                            <button onClick={() => handleDelete(index)}>
                              <FaTrashAlt size={12} className="opacity-30" />
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="font-bold">{item.name}</div>
                <div className="whitespace-pre-wrap">{item.message}</div>
              </div>
            </>
          ))}
        </div>
      </div>

      <div className="mt-3">
        <div className="relative">
          <Textarea
            id="output"
            rows={3}
            onChange={handleInputChangeMessage}
            placeholder="Schreib etwas!"
          />
        </div>

        {normalMode && (
          <button className="submitButton" onClick={handleSaveMessage}>
            Abschicken
          </button>
        )}
        {editMode && (
          <button
            className="bg-green-500 px-4 py-2 font-bold rounded text-white"
            onClick={handleEdit}
          >
            Ersetzen
          </button>
        )}
      </div>
    </div>
  );
};
