import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import Link from "gatsby-link";
export const Preview = ({ drafts }) => {
  return (
    drafts.filter((item) => item.draft)[0] && (
      <div className="contents md:hidden lg:hidden">
        <Link to="/beitraege/vorschau">
          <div className="bg-yellow-100 dark:bg-gray-700 md:bg-white md:dark:bg-gray-800 rounded-lg shadow-sm p-3 mb-4 mt-8 md:mt-0">
            <div className="text-xl mb-5 dark:text-white">In Kürze...</div>
            <div>
              <GatsbyImage
                image={
                  drafts.filter((item) => item.draft)[0].gallery[0]
                    .gatsbyImageData
                }
                alt={drafts.filter((item) => item.draft)[0].title}
              />
              <div className="font-bold text-sm py-1 dark:text-white">
                {
                  drafts
                    .filter((item) => item.draft)
                    .map((item) => item.title)[0]
                }
              </div>
              <div className="text-sm dark:text-white">
                {drafts
                  .filter((item) => item.draft)
                  .map((item) => item.summary)[0]
                  ? drafts
                      .filter((item) => item.draft)
                      .map((item) => item.summary)[0]
                  : drafts
                      .filter((item) => item.draft)
                      .map((item) => item.serie?.title)[0]}
              </div>
            </div>
            <div className="hidden md:contents">
              {drafts.filter((item) => item.draft)[1] && (
                <div className="border-gray-300 mt-6 pt-8 border-t">
                  <GatsbyImage
                    image={
                      drafts.filter((item) => item.draft)[1].gallery[0]
                        .gatsbyImageData
                    }
                    alt={drafts.filter((item) => item.draft)[1].title}
                  />
                  <div className="font-bold text-sm py-1 dark:text-white">
                    {
                      drafts
                        .filter((item) => item.draft)
                        .map((item) => item.title)[1]
                    }
                  </div>
                  <div className="text-sm dark:text-white">
                    {drafts
                      .filter((item) => item.draft)
                      .map((item) => item.summary)[1]
                      ? drafts
                          .filter((item) => item.draft)
                          .map((item) => item.summary)[1]
                      : drafts
                          .filter((item) => item.draft)
                          .map((item) => item.serie?.title)[1]}
                  </div>
                </div>
              )}
            </div>
            <div className="hidden">
              {drafts.filter((item) => item.draft)[1] && (
                <div className="border-gray-300 mt-6 pt-8 border-t">
                  <GatsbyImage
                    image={
                      drafts.filter((item) => item.draft)[1].gallery[0]
                        .gatsbyImageData
                    }
                    alt={drafts.filter((item) => item.draft)[1].title}
                  />
                  <div className="font-bold text-sm py-1 dark:text-white">
                    {
                      drafts
                        .filter((item) => item.draft)
                        .map((item) => item.title)[1]
                    }
                  </div>
                  <div className="text-sm dark:text-white">
                    {drafts
                      .filter((item) => item.draft)
                      .map((item) => item.summary)[1]
                      ? drafts
                          .filter((item) => item.draft)
                          .map((item) => item.summary)[1]
                      : drafts
                          .filter((item) => item.draft)
                          .map((item) => item.serie?.title)[1]}
                  </div>
                </div>
              )}
            </div>
          </div>
        </Link>
      </div>
    )
  );
};
