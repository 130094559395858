import React, { useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import Layout from "../layouts/Layout";
import { GatsbyImage } from "gatsby-plugin-image";
import MoreButton from "../components/MoreButton";
import SiteMetadata from "../components/SiteMetaData";
import { checkRole, gerFormat, gerFormatShort } from "../components/Utils";
import { useAuth0 } from "@auth0/auth0-react";
import {
  FaBookReader,
  FaUser,
  FaComment,
  FaHeart,
  FaLightbulb,
} from "react-icons/fa";
import Neu from "../../static/neu.png";
import HomeSidebar from "../components/HomeSidebar";

const IndexPage = ({ data }) => {
  const contentful = require("contentful-management");
  async function Connect() {
    let client = contentful.createClient({
      accessToken: "CFPAT-jLjpO9lQUXtJ4wgWWXTuwdayKLQMXWZ0s7aUsX9km3U",
    });
    let space = await client.getSpace("j3cnxbdaejpl");
    return await space.getEnvironment("master");
  }
  const [countComment, setCountComment] = useState({});
  const [countRead, setCountRead] = useState({});
  const [countLike, setCountLike] = useState({});
  const [correction, setCorrection] = useState({});
  const [correctionText, setCorrectionText] = useState({});
  const [readPost, setReadPost] = useState({});
  const [likeList, setLikeList] = useState({});
  const [readCommentsList, setReadCommentsList] = useState({});

  useEffect(() => {
    (async () => {
      let env = await Connect();
      let entries = await env.getEntries({ content_type: "portfolio" });
      entries = entries.items.filter((a) => a.fields.comments);
      let countsComment = {};
      entries.forEach((entry) => {
        const id = entry.sys.id;
        countsComment[id] =
          entry.fields.comments["de"].length +
          entry.fields.comments["de"]
            .map((item) => item.replies?.length)
            .filter((item) => item > 0)
            .reduce((a, b) => a + b, 0);
      });
      let countsRead = {};
      entries.forEach((entry) => {
        const id = entry.sys.id;
        countsRead[id] = entry.fields.read["de"][0].counter;
      });
      let countsLike = {};
      entries.forEach((entry) => {
        const id = entry.sys.id;
        countsLike[id] = entry.fields.likes["de"][0].counter;
      });

      let userLiked = {};
      entries.forEach((entry) => {
        const id = entry.sys.id;
        userLiked[id] = entry.fields.likes["de"];
      });
      let userReadComments = {};
      entries.forEach((entry) => {
        const id = entry.sys.id;
        userReadComments[id] = entry.fields.comments["de"][0].read;
      });

      let corrected = {};
      let corrections = {};
      entries.forEach((entry) => {
        const id = entry.sys.id;
        corrected[id] = entry.fields.correction["de"];
        corrections[id] = entry.fields.correction?.["de"][0].correctionText;
      });

      let userRead = {};
      entries.forEach((entry) => {
        const id = entry.sys.id;
        userRead[id] = entry.fields.read["de"];
      });

      setCountRead(countsRead);
      setCountLike(countsLike);
      setCountComment(countsComment);
      setCorrection(corrected);
      setCorrectionText(corrections);
      setReadPost(userRead);
      setLikeList(userLiked);
      setReadCommentsList(userReadComments)
    })();
  }, []);
  const { isAuthenticated, user } = useAuth0();
  const [numPosts, setNumPosts] = useState(6);
  const allCards = data.allContentfulPost.nodes
    .filter((a) => a.draft !== true)
    .slice(0, numPosts);
  const totalNumPosts =
    data.allContentfulPost.nodes.length -
    data.allContentfulPost.nodes.filter((item) => item.draft).length;
  const visible = numPosts < totalNumPosts;

  const postsWithSerie = data.allContentfulPost.nodes
    .filter((item) => (item.serie ? item.serie.title : false))
    .reverse();

  const Counter = ({ item }) => {
    return (
      <div className="text-sm">
        {checkRole(user, "admin") &&
          correctionText[item.contentful_id] !== "" &&
          correction[item.contentful_id] !== undefined &&
          !correction[item.contentful_id].some(
            (d) => d.userId === user.sub
          ) && (
            <div className="right-0 bottom-0 absolute relative transform -translate-x-45">
              <FaLightbulb className=" text-yellow-200" />
            </div>
          )}
        <div className="right-0 bottom-0 absolute relative transform -translate-x-32">
          <FaBookReader className="opacity-30" />
          <div className="opacity-30 absolute right-0 transform translate-x-6 top-1/2 -translate-y-1/2">
            <div className="w-5 text-center">
              {countRead[item.contentful_id]}
            </div>
          </div>
        </div>
        <div className="right-0 bottom-0 absolute relative transform -translate-x-19">
          <FaComment className={
              readCommentsList[item.contentful_id] !== undefined &&
              user?.sub &&
              !readCommentsList[item.contentful_id].some((d) => d.userId === user.sub) && countComment[item.contentful_id] && countComment[item.contentful_id]-1 !== 0 && checkRole(user) 
                ? "text-blue-500":"opacity-30"}/>
          <div className="opacity-30 absolute right-0 transform translate-x-6 top-1/2 -translate-y-1/2">
            <div className="w-5 text-center">
              {countComment[item.contentful_id] && countComment[item.contentful_id]-1}
            </div>
          </div>
        </div>
        <div className="right-0 bottom-0 absolute relative transform -translate-x-6">
          <FaHeart
            className={
              likeList[item.contentful_id] !== undefined &&
              user?.sub &&
              likeList[item.contentful_id].some((d) => d.userId === user.sub)
                ? "text-red-500"
                : "opacity-30"
            }
          />
          <div className="opacity-30 absolute right-0 transform translate-x-6 top-1/2 -translate-y-1/2">
            <div className="w-5 text-center">
              {countLike[item.contentful_id]}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const BodyAuthenticated = () => {
    return (
      (checkRole(user, "admin") ||
        checkRole(user, "standardbenutzer")) && 
      <div className="md:col-span-3">
        <div className="rounded-lg shadow-sm transform overflow-hidden">
          {allCards.map((item, index) => (
            <div className="mb-6 sm:mb-0.5 last:mb-0" key={index}>
              <Link to={`/beitraege/${item.slug.toLowerCase()}`}>
                <div className="bg-white dark:bg-gray-800 sm:h-44 sm:flex mobilesettings transform overflow-hidden relative">
                  <div className="relative sm:w-2/5">
                    <GatsbyImage
                      className="h-full "
                      image={item.gallery[0].gatsbyImageData}
                      alt={item.title}
                    />
                    {readPost[item.contentful_id] !== undefined &&
                      !readPost[item.contentful_id].some(
                        (d) => d.userId === user.sub
                      ) && (
                        <img
                          className="w-16 h-16 absolute top-0 right-0 z-60"
                          src={Neu}
                          alt="neu"
                        />
                      )}
                  </div>
                  <div className="sm:w-3/5 m-3 pb-0.5 relative">
                    <div className="flex justify-between">
                      <div className="text-sm text-gray-500 hidden sm:contents dark:text-gray-400">
                        {gerFormat(item.publishedAt)}
                      </div>

                      <div className="text-sm text-gray-500 sm:hidden dark:text-gray-400">
                        {gerFormatShort(item.publishedAt)}
                      </div>
                      <div className="flex items-center text-gray-500">
                        <FaUser className="mr-0.5 opacity-70" size={11} />
                        <div className="text-sm">{item.author}</div>
                      </div>
                    </div>
                    <div className="text-2xl font-bold py-3 sm:py-0 sm:pt-3 sm:pb-2.5">
                      {item.title}
                    </div>
                    <div className="mb-4 h-12 sm:h-0">
                      {item.serie ? item.serie.title : item.summary}{" "}
                      {postsWithSerie
                        .filter((a) =>
                          item.serie
                            ? a.serie.title === item.serie.title
                            : false
                        )
                        .indexOf(item) !== -1 &&
                        `(${postsWithSerie
                          .filter((a) =>
                            item.serie
                              ? a.serie.title === item.serie.title
                              : false
                          )
                          .indexOf(item) + 1
                        })`}
                    </div>
                    <Counter item={item} />
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>{" "}
        {visible && (
          <div className="flex justify-center mt-8 w-full relative">
            <MoreButton
              numObjects={numPosts}
              setNumObjects={setNumPosts}
              plusNumber={6}
            />
            {allCards.length < 13 && (
              <div className="absolute z-10 left-1/2 top-1/2 flex justify-center items-center transform -translate-y-1/2">
                <Link to="/beitraege">
                  <div className="text-white bg-blue-400 dark:bg-gray-800 rounded-r-full pl-7 pr-4 h-10 flex items-center">
                    <p className="animate-pulse">Zur Übersicht</p>
                  </div>
                </Link>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  const BodyNotAuthenticated = () => {
    return (
      (!isAuthenticated || !checkRole(user)) &&
      <div className="md:col-span-3 order-2 lg:order-none">
        <div className=" rounded-lg shadow-sm overflow-hidden transform">
          {allCards.map((item, index) => (
            <div className="mb-6 sm:mb-0.5 last:mb-0" key={index}>
              <div className="bg-white dark:bg-gray-800 sm:h-44 sm:flex mobilesettings overflow-hidden transform relative">
                <div className="relative sm:w-2/5">
                  <GatsbyImage
                      className="h-full "
                      image={item.gallery[0].gatsbyImageData}
                      alt={item.title}
                  />
                </div>
                <div className="sm:w-3/5 m-3 relative">
                  <div className="flex justify-between">
                  <div className="text-sm text-gray-500 hidden sm:contents dark:text-gray-400">
                    {gerFormat(item.publishedAt)}
                  </div>
                  <div className="text-sm text-gray-500 sm:hidden dark:text-gray-400">
                    {gerFormatShort(item.publishedAt)}
                  </div>
                  <div className="flex items-center text-gray-500">
                    <FaUser className="mr-0.5 opacity-70" size={11} />
                    <div className="text-sm">Mustermann</div>
                  </div>
                  </div>
                  <div className="text-2xl font-bold py-3 sm:py-0 sm:pt-3 sm:pb-2.5">
                    Titel
                  </div>
                  <div className="mb-4 h-12 sm:h-0">
                    Um den Inhalt zu sehen, musst du dich einloggen.
                  </div>
                  <div className="text-2xl font-bold py-3 sm:py-0 sm:pt-3 sm:pb-2.5 h-16 sm:h-0"></div>
                  <Counter item={item} />
                </div>
              </div>
            </div>
          ))}
        </div>{" "}
      </div>
    );
  };

  return (
    <Layout>
      <SiteMetadata title="Home" description="Herzlich Willkommen!" />
      <div className="bg-gray-50 dark:bg-gray-900 flex-grow pb-6 dark:text-white">
        <div className="container">
          <div className="text-center text-4xl py-6 lg:hidden">Home</div>
          <div className="lg:grid lg:grid-cols-4 lg:gap-3 flex flex-col lg:flex-none">
            <BodyAuthenticated />
            <BodyNotAuthenticated />
            <HomeSidebar />
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default IndexPage;

export const query = graphql`
  query IndexQuery {
    allContentfulPost(sort: { fields: publishedAt, order: DESC }) {
      nodes {
        title
        contentful_id
        draft
        slug
        serie {
          title
        }
        summary
        richText {
          raw
        }
        publishedAt(formatString: "DD-MM-YYYY")
        author
        gallery {
          title
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            width: 1000
            placeholder: BLURRED
            aspectRatio: 1.77
          )
        }
      }
    }
  }
`;
