import { martin } from "./Auth";
import React from "react";

export const Boss = ({ user }) => {
  return (
    <div className="text-xl mb-5 dark:text-white">
      {user?.sub === martin ? "Hello Boss!" : "Willkommen!"}
    </div>
  );
};
