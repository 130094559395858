import React from "react";
import { FaPlus } from "react-icons/fa";

const MoreButton = ({ setNumObjects, numObjects, plusNumber }) => {
  return (
    <button
      className="relative z-20 focus:outline-none bg-blue-300 dark:bg-blue-900 h-10 w-10 rounded-full text-white text-2xl rounded-md shadow-sm"
      onClick={() => setNumObjects(numObjects + plusNumber)}
    >
      <div className="absolute top-1/2 transform -translate-y-1/2 right-1/2 translate-x-1/2">
        <FaPlus size={15} />
      </div>
    </button>
  );
};

export default MoreButton;
